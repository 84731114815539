import {
  createColumnText,
  createColumnNumber,
  createColumnDate,
  formatNumber,
  widthOptions,
} from '@/components/table/function/createColumn';
import { ROUTES_PATH } from '@/resources/routes-name';
import {
  createColumnButtonArr,
  createColumnTag,
} from '../../../../components/table/function/createColumn';
import { Popconfirm } from 'antd';
import { ButtonTheme, ButtonToolTip } from '../../../../components';
import { WARRANTY_STATUS } from '../../../../resources/status';

export const columns = ({ handleDelete }) => {
  return [
    createColumnTag(
      'สถานะ',
      { key: 'warrantyStatus', text: 'warrantyStatus', icon: true },
      WARRANTY_STATUS,
    ),
    createColumnText('เลขที่รับประกัน', 'warrantyCode', widthOptions(150)),
    createColumnText('ชื่อ-นามสกุล', 'fullName', widthOptions(150)),
    createColumnText('ชื่อไลน์', 'lineName', widthOptions(120)),
    createColumnText('เบอร์ติดต่อ', 'telephone', widthOptions(150)),
    createColumnText('รหัสสินค้า', 'itemCode', widthOptions(210)),
    createColumnText('ชื่อสินค้า', 'productName', widthOptions(150)),
    createColumnText('รหัสรับประกัน', 'warrantyNumberCode', widthOptions(160)),
    createColumnText('แบรนด์', 'mobileBrandName', widthOptions(100)),
    createColumnText('รุ่น', 'mobileModelName', widthOptions(150)),
    createColumnText('ช่องทางการซื้อ', 'channelBuyName', widthOptions(230)),
    createColumnText('ร้านค้า', 'storeName', widthOptions(150)),
    createColumnDate('วันที่ลงทะเบียน', 'createDate', widthOptions(170)),
    createColumnDate('วันหมดอายุ', 'expireDate', widthOptions(150)),
    createColumnNumber(
      'วันคงเหลือ',
      'remainingDays',
      widthOptions(150, {
        render: (value) => {
          if (value > 100000) {
            return <div>ตลอดชีวิต</div>;
          } else {
            return formatNumber(value);
          }
        },
      }),
    ),
    createColumnButtonArr('จัดการ', 'rowNo', {
      others: {
        fixed: 'right',
        width: 170,
      },
      buttonArr: [
        {
          useFor: (record) => (record.warrantyStatus === 'CLAIMING' ? 'DETAIL' : 'EDIT3'),
          render: (text, record) => {
            if (record.warrantyStatus === 'COMPLETED') {
              return (
                <ButtonToolTip toolTip="ดูรายละเอียด">
                  <ButtonTheme
                    useFor="DETAIL"
                    onClick={() => {
                      const url = `${ROUTES_PATH.ROUTE_REGISTRATION_VIEW.PATH}?code=${record.warrantyCode}`;
                      window.open(url, '_blank');
                    }}
                  />
                </ButtonToolTip>
              );
            }
            return (
              <ButtonToolTip toolTip="แก้ไข">
                <ButtonTheme
                  useFor="EDIT3"
                  onClick={() => {
                    const url = `${ROUTES_PATH.ROUTE_EDIT_WARRANTY.PATH}?code=${record.warrantyCode}`;
                    window.open(url, '_blank');
                  }}
                />
              </ButtonToolTip>
            );
          },
        },
        {
          useFor: 'DELETE2',
          render: (text, record) => {
            if (record.warrantyStatus !== 'ACTIVE') {
              return null;
            }
            return (
              <ButtonToolTip toolTip="ยกเลิกรับประกัน" placement="topLeft">
                <Popconfirm
                  title="คุณต้องการยกเลิกการรับประกันหรือไม่?"
                  onConfirm={() => handleDelete(record)}
                  okText="ใช่"
                  cancelText="ไม่"
                >
                  <ButtonTheme useFor="DELETE2" />
                </Popconfirm>
              </ButtonToolTip>
            );
          },
        },
      ],
    }),
  ];
};

export const exportHeaders = [
  { dataIndex: 'warrantyCode', title: 'เลขที่รับประกัน' },
  { dataIndex: 'warrantyNumberCode', title: 'รหัสรับประกัน' },
  { dataIndex: 'categoryCode', title: 'หมวดหมู่' },
  { dataIndex: 'warrantyStatus', title: 'สถานะ' },
  { dataIndex: 'mobileBrandName', title: 'แบรนด์' },
  { dataIndex: 'mobileModelName', title: 'รุ่น' },
  { dataIndex: 'itemCode', title: 'รหัสสินค้า' },
  { dataIndex: 'productName', title: 'ชื่อสินค้า' },
  { dataIndex: 'channelBuyName', title: 'ช่องทางการซื้อ' },
  { dataIndex: 'fullName', title: 'ชื่อ-นามสกุล' },
  { dataIndex: 'telephone', title: 'เบอร์โทร' },
  { dataIndex: 'email', title: 'อีเมลล์' },
  { dataIndex: 'createDate', title: 'วันที่ลงทะเบียน' },
  { dataIndex: 'claimCode', title: 'เลขที่เคลม' },
  { dataIndex: 'claimRemark', title: 'สาเหตุการเคลม' },
  { dataIndex: 'claimDate', title: 'วันที่เคลม' },
  { dataIndex: 'lineUid', title: 'ไลน์ UID' },
];
