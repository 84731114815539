import React from 'react';
import { CardTheme, TextLarge, RenderForm, ButtonTheme } from '../../../components/index';
import { StyledContainer } from './styles';
import { useForm } from 'react-hook-form';
import { FORM_USER_REGISTRATION_WARRANTY, FORM_PRODUCT_REGISTRATION_WARRANTY } from './forms';
import { Row, Col, Spin } from 'antd';
import { useEffect, useMemo, useState } from 'preact/hooks';
import { useGetWarrantyEdit } from './hooks/useGetWarrantyEdit';
import {
  channelBuyQuery,
  storeTypeTypesQuery,
  warrantyTypesQuery,
} from '../../../services/react-query/optionMasterQuery';
import { onUploadFile } from './hooks/onUploadFile';
import { handleSubmitForm } from './func/submitHandler';
import { useOnChangeValue } from './func/onChangeValue';
import { ROUTES_PATH } from '../../../resources/routes-name';
import { navigateTo } from '../../../utils/navigation';
import { useOptionQuery } from './hooks/useOptionQuery';
import { ContainerButton } from '../../../styles/global-style';
import { useModal } from '../../../components/modal/hooks/useModal';
import { EditExpiredModal } from './modal/EditExpiredModal';

export const EditWarrantyProduct = () => {
  const [showStoreField, setShowStoreField] = useState('');
  const { open: openEditExpired, onToggle: onToggleEditExpired } = useModal();
  const { control, setValue, getValues, handleSubmit, reset, watch } = useForm({
    defaultValues: { mapProductId: '' },
  });
  const [products, setProducts] = useState([]);
  const { data: channelBuyList, isFetching: channelBuyLoading } = channelBuyQuery();
  const { data: warrantyTypesList, isFetching: warrantyTypesLoading } = warrantyTypesQuery();
  const { data: storeTypeChannelOptions } = storeTypeTypesQuery(showStoreField);
  const [, setIsSubmitting] = useState(false);

  const channelBuyId = watch('channelBuyId');

  const { mutate: mutateUpload, isPending: isPendingUpload } = onUploadFile({
    onSuccessCallBack: (response) => {
      setIsSubmitting(false);
    },
  });
  const [state, setState] = useState();

  const queryParams = new URLSearchParams(window.location.search);
  const warrantyCode = queryParams.get('code');

  useEffect(() => {
    if (warrantyCode) {
      setState({ warrantyCode });
    }
  }, [warrantyCode]);

  const { dataSource: data, loading: editWarrantyLoading } = useGetWarrantyEdit({
    warrantyCode: state?.warrantyCode,
    reset,
    setProducts,
    setValue,
  });

  const warranty = data?.warranty;

  const { mobileBrandOptions, modelOptions, productOptions } = useOptionQuery({
    products,
    watch,
    setValue,
  });

  useEffect(() => {
    if (channelBuyId && channelBuyList) {
      const selectedChannel = channelBuyList.find((option) => option.value === channelBuyId);
      const channelType = selectedChannel?.channelBuyType;
      if (['FOCUS', 'STORE'].includes(channelType)) {
        setShowStoreField(channelType);
      } else {
        setShowStoreField('');
      }
    }
  }, [channelBuyId, channelBuyList]);

  useEffect(() => {
    if (showStoreField && data?.warranty?.storeId) {
      setValue('storeId', data.warranty.storeId);
    }
  }, [showStoreField, data?.warranty?.storeId, setValue]);

  const onChangeValue = useOnChangeValue(setValue);
  const loading = useMemo(() => isPendingUpload, [isPendingUpload]);
  const onSubmit = (values) => handleSubmitForm(values, setIsSubmitting, mutateUpload);

  const goBack = () => {
    navigateTo({
      pathname: ROUTES_PATH.ROUTE_REGISTRATION.PATH,
      state: getValues(),
    });
  };

  return (
    <Spin spinning={channelBuyLoading || warrantyTypesLoading || editWarrantyLoading}>
      <StyledContainer>
        <TextLarge text="แก้ไขข้อมูลรับประกัน" />
        <Row gutter={16}>
          <Col span={14}>
            <CardTheme>
              <TextLarge text="ลูกค้าลงทะเบียนรับประกัน" />
              <RenderForm
                control={control}
                setValue={setValue}
                getValues={getValues}
                forms={FORM_USER_REGISTRATION_WARRANTY()}
              />
              <ContainerButton right>
                <ButtonTheme useFor="EDIT3" title="แก้ไขวันหมดอายุ" onClick={onToggleEditExpired} />
                {openEditExpired && (
                  <EditExpiredModal
                    open={openEditExpired}
                    onToggle={onToggleEditExpired}
                    initialValues={warranty}
                  />
                )}
              </ContainerButton>
            </CardTheme>
          </Col>
          <Col span={10}>
            <CardTheme>
              <TextLarge text="สินค้าลงทะเบียนรับประกัน" />
              <RenderForm
                control={control}
                setValue={setValue}
                getValues={getValues}
                onChange={onChangeValue}
                forms={FORM_PRODUCT_REGISTRATION_WARRANTY({
                  mobileBrandOptions,
                  modelOptions,
                  productOptions,
                  channelBuyList,
                  warrantyTypesList,
                  storeTypeChannelOptions,
                  showStoreField,
                })}
              />
            </CardTheme>
          </Col>
        </Row>
        <Row gutter={16} justify="space-between" align="middle" style={{ marginTop: '2%' }}>
          <Col>
            <ButtonTheme useFor="BACK" title="ย้อนกลับ" onClick={goBack} />
          </Col>
          <Col>
            {loading ? (
              <Spin spinning={loading}></Spin>
            ) : (
              <ButtonTheme useFor="SUBMIT" title="ลงทะเบียน" onClick={handleSubmit(onSubmit)} />
            )}
          </Col>
        </Row>
      </StyledContainer>
    </Spin>
  );
};
