export const FORM_USER_REGISTRATION_WARRANTY = ({ onEnter }) => {
  return [
    {
      name: 'telephone',
      label: 'เบอร์ติดต่อ (กรอกเบอร์ติดต่อแล้วกด Enter)',
      type: 'INPUT_PHONE',
      placeholder: '',
      onEnter,
      span: 12,
      rules: {
        required: true,
        pattern: /^[0-9]{10}$/,
      },
      maxLength: 10,
    },
    {
      name: 'fullName',
      label: 'ชื่อ-นามสกุล',
      type: 'INPUT',
      placeholder: '',
      span: 12,
      rules: {
        required: true,
      },
    },
  ];
};

export const FORM_PRODUCT_REGISTRATION_WARRANTY = ({
  onEnter,
  mobileBrandOptions = [],
  mobileModelOptions = [],
  productOptions = [],
  channelBuyList = [],
  warrantyTypesList = [],
  storeTypeChannelOptions = [],
  showStoreField,
}) => {
  const span = 24;
  const accept = [`image/jpeg`, 'image/png', 'image/jpg', 'image/bmp', 'image/gif'];
  const SHOW_STORE = [
    {
      name: 'storeId',
      label: 'ร้านค้า',
      type: 'DROPDOWN',
      properties: {
        options: storeTypeChannelOptions || [],
      },
      span,
      rules: {
        required: true,
      },
    },
  ];
  return [
    {
      name: 'warrantyNumberCode',
      label: 'รหัสรับประกันสินค้า (กรอกรหัสรับประกันแล้วกด Enter) ',
      type: 'INPUT',
      placeholder: '',
      span,
      onEnter,
      rules: {
        required: true,
      },
    },
    {
      name: 'mobileBrandId',
      label: 'ยี่ห้อมือถือ',
      type: 'DROPDOWN_TWO',
      placeholder: '',
      properties: {
        options: mobileBrandOptions || [],
      },
      span,
      rules: {
        required: true,
      },
      style: { width: '100%', height: '100%', minHeight: '32px' },
    },
    {
      name: 'mobileModelId',
      label: 'รุ่นมือถือ',
      type: 'DROPDOWN_TWO',
      placeholder: '',
      properties: {
        options: mobileModelOptions || [],
      },
      span,
      rules: {
        required: true,
      },
      style: { width: '100%', height: '100%', minHeight: '32px' },
    },
    {
      name: 'mapProductId',
      label: 'ชื่อสินค้า',
      type: 'DROPDOWN_TWO',
      placeholder: '',
      properties: {
        options: productOptions || [],
      },
      span,
      rules: {
        required: true,
      },
      style: { width: '100%', height: '100%', minHeight: '32px' },
    },
    {
      name: 'channelBuyId',
      label: 'ช่องทางการซื้อขาย',
      type: 'DROPDOWN',
      placeholder: '',
      properties: {
        options: channelBuyList,
      },
      span,
      rules: {
        required: true,
      },
    },
    ...(['FOCUS', 'STORE']?.includes(showStoreField) ? SHOW_STORE : []),
    {
      name: 'warrantyTypeId',
      label: 'ประเภทรับประกัน',
      type: 'DROPDOWN',
      placeholder: '',
      properties: {
        options: warrantyTypesList,
      },
      span,
      rules: {
        required: true,
      },
    },
    {
      name: 'infoPicture',
      label: 'ชนิดรูปภาพที่ต้องการ (.png, .jpg, .jpeg, .bmp, .gif)',
      type: 'TEXT',
      span,
    },
    {
      name: 'invImage',
      label: 'รูปใบเสร็จ ',
      type: 'UPLOAD',
      accept: accept.join(','),
      listType: 'picture-card',
      span: 12,
      maxCount: 1,
      rules: {
        required: true,
      },
    },
    {
      name: 'packageImage',
      label: 'รูปสินค้า',
      type: 'UPLOAD',
      listType: 'picture-card',
      span: 12,
      maxCount: 1,
      rules: {
        required: true,
      },
    },
  ];
};
