export const handleSubmitForm = (values, setIsSubmitting, mutateUpload) => {
  setIsSubmitting(true);

  const uploadData = {
    invImage: values.invImage ? values.invImage[0] : null,
    packageImage: values.packageImage ? values.packageImage[0] : null,
    telephone: values.telephone,
    fullName: values.fullName,
    warrantyNumberCode: values.warrantyNumberCode,
    mobileBrandId: values.mobileBrandId,
    mobileModelId: values.mobileModelId,
    mapProductId: values.mapProductId,
    channelBuyId: values.channelBuyId,
    warrantyTypeId: values.warrantyTypeId,
    storeId: values.storeId,
  };

  mutateUpload(uploadData);
};
