export const InsertWarranty = (value) => {
  return {
    invImage: value.invImage ? value.invImage.originFileObj : null,
    packageImage: value.packageImage ? value.packageImage.originFileObj : null,
    telephone: value.telephone,
    fullName: value.fullName,
    warrantyNumberCode: value.warrantyNumberCode,
    mobileBrandId: value.mobileBrandId,
    mobileModelId: value.mobileModelId,
    mapProductId: value.mapProductId,
    channelBuyId: value.channelBuyId,
    warrantyTypeId: value.warrantyTypeId,
    storeId: value.storeId,
  };
};
