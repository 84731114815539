import React, { useState, useEffect } from 'react';
import { CardTheme } from '../../../components/card';
import { TextLarge } from '../../../components/text';
import { RenderForm } from '../../../components/forms';
import { StyledContainer } from './styles';
import { ButtonTheme } from '../../../components/buttons';
import { useForm } from 'react-hook-form';
import { FORM_USER_REGISTRATION_WARRANTY, FORM_PRODUCT_REGISTRATION_WARRANTY } from './forms';
import { Row, Col, Spin } from 'antd';
import { useCallback, useMemo } from 'preact/hooks';
import {
  channelBuyQuery,
  storeTypeTypesQuery,
  warrantyTypesQuery,
} from '../../../services/react-query/optionMasterQuery';
import { onUploadFile } from './hooks/onUploadFile';
import { handleSubmitForm } from './func/submitHandler';
import { useTeleQuery } from './hooks/useGetTeleQuery';
import { useWarrantyQuery } from './hooks/useWarrantyQuery';
import { useOptions } from './hooks/useOptions';
import { useOnChangeValue } from './hooks/useOnchangeValue';
import { ContainerButton } from '../../../styles/global-style';

export const RegistrationProduct = () => {
  const [showStoreField, setShowStoreField] = useState('');
  const { control, setValue, getValues, handleSubmit, watch, reset } = useForm();
  const { data: channelBuyList } = channelBuyQuery();
  const { data: storeTypeChannelOptions } = storeTypeTypesQuery(showStoreField);
  const { data: warrantyTypesList } = warrantyTypesQuery();
  const [_, setIsSubmitting] = useState(false);
  const { mutate: mutateUpload, isPending: isPendingUpload } = onUploadFile({
    onSuccessCallBack: (response) => {
      setIsSubmitting(false);
    },
  });

  const channelBuyId = watch('channelBuyId');

  useEffect(() => {
    if (channelBuyId && channelBuyList) {
      const selectedChannel = channelBuyList.find((option) => option.value === channelBuyId);
      const channelType = selectedChannel?.channelBuyType;
      if (['FOCUS', 'STORE'].includes(channelType)) {
        setShowStoreField(channelType);
      } else {
        setShowStoreField('');
      }
    }
  }, [channelBuyId, channelBuyList]);

  const { mutate: onCallTele, isPending: isPendingTele } = useTeleQuery({ reset, getValues });
  const { mutate: onCallWarranty, isPending: isPendingWarranty } = useWarrantyQuery({
    reset,
    getValues,
  });

  const { mobileBrandOptions, mobileModelOptions, productOptions } = useOptions({ watch });

  const onChangeValue = useOnChangeValue(setValue);

  const loading = useMemo(() => isPendingUpload, [isPendingUpload]);

  const onSubmit = (values) => handleSubmitForm(values, setIsSubmitting, mutateUpload);

  const handleOnEnter = useCallback(
    async (value, item) => {
      switch (item?.name) {
        case 'telephone':
          onCallTele(value);
          break;
        case 'warrantyNumberCode':
          onCallWarranty(value);
          break;
        default:
          break;
      }
    },
    [onCallTele, onCallWarranty],
  );

  useEffect(() => {
    if (mobileBrandOptions.length === 1) {
      setValue('mobileBrandId', mobileBrandOptions[0]?.value);
    }
    if (mobileModelOptions.length === 1) {
      setValue('mobileModelId', mobileModelOptions[0]?.value);
    }
    if (productOptions.length === 1) {
      setValue('mapProductId', productOptions[0]?.value);
    }
  }, [mobileBrandOptions, mobileModelOptions, productOptions, setValue]);

  return (
    <Spin spinning={isPendingTele || isPendingWarranty}>
      <StyledContainer>
        <TextLarge text="ลงทะเบียนสินค้า" />
        <Row gutter={16}>
          <Col span={14}>
            <CardTheme>
              <TextLarge text="ลูกค้าลงทะเบียนรับประกัน" />
              <RenderForm
                control={control}
                setValue={setValue}
                getValues={getValues}
                forms={FORM_USER_REGISTRATION_WARRANTY({ onEnter: handleOnEnter })}
              />
            </CardTheme>
          </Col>
          <Col span={10}>
            <CardTheme>
              <TextLarge text="สินค้าลงทะเบียนรับประกัน" />
              <RenderForm
                control={control}
                setValue={setValue}
                getValues={getValues}
                onChange={onChangeValue}
                forms={FORM_PRODUCT_REGISTRATION_WARRANTY({
                  onEnter: handleOnEnter,
                  productOptions,
                  mobileBrandOptions,
                  mobileModelOptions,
                  channelBuyList,
                  warrantyTypesList,
                  showStoreField,
                  storeTypeChannelOptions,
                })}
              />
            </CardTheme>
          </Col>
        </Row>
        <ContainerButton right>
          {loading ? (
            <Spin spinning={loading}></Spin>
          ) : (
            <ButtonTheme
              useFor="SUBMIT"
              title="ลงทะเบียน"
              style={{ marginTop: '2%' }}
              onClick={handleSubmit(onSubmit)}
            />
          )}
        </ContainerButton>
      </StyledContainer>
    </Spin>
  );
};
